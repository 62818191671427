.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #222222;
}

.Inner {
  flex: 1;
  margin: 2em;
  display: flex;
  flex-direction: column;
}

.CenterText {
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10em;
  margin: 0;
  transform: translate(-50%, -50%);
}
